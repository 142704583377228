.Privacy{

    width:80%;
    margin-left:auto;
    margin-right: auto;
}

.Privacy p 
{
    font-size:20px !important;


}
.Privacy h2{
    text-align: center;
}