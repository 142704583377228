.closeSession{
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;

    z-index:2000;
}

.closeSessionWindow
{
    position: fixed;
   top:15%;
   bottom:40%;
   left:25%;
   right:25%;
    background-color: #fff;
    border-radius: 5%;;
    text-align: justify;
    padding:50px;

}

.closeSessionWindow button{
    text-align: center;
    width:100%;
    min-height:50px;
    line-height: 50px;;
    font-size:larger;
    border-radius: 5px;
    border:none;
    background-color: rgb(255, 86, 224,0.9);
    color:#fff;
    font-weight:900;
    margin-top:70px;
    

}
.closeSessionWindow button:hover{
    background-color: rgb(255, 188, 255);
color:rgb(146, 37, 128);
transition: 500ms;
cursor:pointer;
}
.closeSession .closeSessionWindow h2
{
    font-size:20px;
}


@media (max-width: 750px) {
    .closeSessionWindow{
      top:0;
      bottom:0;
      left:0;
      right:0;
      border-radius:0;
    } 
  }