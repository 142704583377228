
.Loaderbg
{
    background-color: rgba(255, 255, 255, 0.8);
    position:fixed;
    z-index:2;
    width:100vw;
    height:100vh;
   


}

.Loaderimg{
    
    width:40%;
    height:40%;   
    position: fixed;
   
    top:calc(50vh - 20%);
    left:calc(50vw - 20%)
    


}
.Loaderimg img 
{
    width:100%;
    height:100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
;
}
.Loaderimg img:first-child
{
    animation: shake 1.2s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

}
.Loaderimg img:nth-child(2)
{
    opacity: 1;
    animation: fade 1s linear infinite, rotation 1s  linear;

}
@keyframes rotation {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }}
@keyframes fade {
    0%,100% { opacity: 0 }
    50% { opacity: 1 }
}
@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes mymove {
    from {background-color: rgb(0, 0, 0,0.8);}
    to {background-color: rgb(255, 255, 255, 0.8);}
  }
  @media screen and (max-width: 992px)
  {
    .Loaderimg
    {
        width:70%;
        height:70%;
      top:15%;
      left:15%;
    }
    
  }